import React, { useState } from 'react';
import './DeleteAccountGuide.css';
import Navbar from '../navBar/Navbar';
import { apiClient } from '../../services/APIClient';

const DeleteAccountGuide: React.FC = () => {
    const [email, setEmail] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            setIsSubmitted(false);
            const response = await apiClient.post('/users/request-deletion', { email });
            if (response.status === 404) {
                alert('User not found');
                return;
            }
            setIsSubmitted(true);
            setEmail('');
        } catch (error) {
            console.error('Error submitting delete request:', error);
            // Handle error (you might want to show an error message to the user)
        }
    };

    return (
        <div className='content-for-user-action'>
            <Navbar />
            <div className="delete-account-guide">
                <h1>Delete Your Account</h1>
                    <div className="warning-note">
                        <p><strong>Note:</strong> This action cannot be undone. All your data will be permanently deleted.</p>
                    </div>
                    
                    <form onSubmit={handleSubmit} className="delete-account-form">
                        <div className="form-group">
                            <label htmlFor="email">Confirm your email address:</label>
                            <input
                                type="email"
                                id="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                                placeholder="Enter your email"
                            />
                        </div>
                        <button type="submit">Request Account Deletion</button>
                    </form>

                    {isSubmitted && (
                        <div className="success-message">
                            <p>Your account deletion request has been submitted. We will let you know when your account has been deleted.</p>
                        </div>
                    )}
                </div>
        </div>
    );
};

export default DeleteAccountGuide;